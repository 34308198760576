import React, { useState } from 'react';
import { Container, FormGroup, Label, Input } from 'reactstrap';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { callAPI } from '../../../helpers/api';
import { useSelector } from 'react-redux';
import FormModalBase from '../../common/modals/FormModalBase';
import { Action } from '../../../helpers/enums';
import { getButtonText, getOption } from '../../../helpers/commonHelpers';
import { SelectWithStyles } from '../../common/CustomSelect';

const schema = yup.object().shape({
    factory: yup.array().min(1, 'Select at least one Factory').required('Select a Factory')
});

function ConnectionModal(props) {
    const [submitted, setSubmitted] = useState(0);
    const { handleSubmit, reset, setError, setValue, formState: {errors, isValid}, control} = useForm({resolver: yupResolver(schema)});
    const activeStorage = useSelector(state => state.adminStorages.activeItem); 
    const storageFactories = useSelector(state => state.adminStorages.storageFactories);

    // Only not yet selected factories shown in dropdown
    const factoryOptions = storageFactories?.length > 0 ?
        storageFactories.filter(factory => !factory.isSelected).map(factory => {
        return getOption(factory.id, factory.code, factory.name, factory.factorySymbol)
        }) : [];

    //console.log('props', props);
    //console.log('errors', errors);
    //console.log('storageFactories', storageFactories);

    const modalIsClosed = () => {
        reset();
        setValue('factory', []);
    }

    const onSubmit = async (data) => {
        //console.log('onSubmit data', data);
        let body = { storageId : data.id, connectedEntityIds: data.factory.map(f => f.value)};
        let result = await callAPI('storagefactory', props.msalInstance, props.user, body, 'POST');
        if (result.errorMsg && !result.json){
            setError("formSubmit", {
                type:"manual",
                message: result.errorMsg
            }); 
        }
        else {
            //console.log(result);
            const addedFactories = result.json;
            if (addedFactories?.length > 0) {
                if (props.onConnectionsAdded) {
                    props.onConnectionsAdded(addedFactories);
                }
            }
            modalIsClosed();
            props.toggle();
        }
        setSubmitted(submitted + 1);
    }
    
    return (
        <Container>
            <FormModalBase
                title={Action.ADD + " connection"}
                isOpen={props.isOpen}
                toggle={props.toggle}
                onSubmit={handleSubmit(data => onSubmit(data))}
                actionButtonText={getButtonText(Action.ADD)}
                onModalIsClosing={modalIsClosed}
                formIsValid={isValid}
                setNotLoading={submitted}
            >
                <p>Add new factories for storage '<span className="fw-bold">{activeStorage.code}, {activeStorage.name}</span>'</p>
                <Controller name="id" control={control} defaultValue={activeStorage.id}
                    render={({ field }) => <Input {...field} type="hidden" />}
                />
                <FormGroup className="form-group">
                    <Label>Factory</Label>
                    <Controller name="factory" control={control} defaultValue="0" render={({ field }) =>
                        <SelectWithStyles {...field}
                            options={factoryOptions} isClearable isMulti
                        />
                        }
                    />
                </FormGroup>
                {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                {errors.formSubmit && <p className="text-danger">{errors.formSubmit.message}</p>}
            </FormModalBase>
        </Container>
    );
}

export default ConnectionModal;